<template>
  <AdminUserForm
    :user="user"
    title="Invite New Admin User"
    description="Provide details of the new user to invite. Inviting a user will send them
      an email with instructions to register for this service."
    @submit="addUser"
    :parentLoading="loading"
    @loading="setLoading"
  />
</template>

<script>
import AdminUserForm from "@/components/admin/AdminUserForm.vue";
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import AdminUserService from "@/services/admin/AdminUserService.js";
export default {
  name: "AdminUserAdd",
  title: "Admin - User Add",
  components: {
    AdminUserForm
  },
  data() {
    return {
      user: {
        userRole: null,
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        userParks: []
      },
      loading: false
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    setLoading(val) {
      this.loading = val;
    },
    async addUser(request) {
      this.loading = true;
      const profileService = new AdminProfileService(this.tenantId);
      const userService = new AdminUserService(this.tenantId);
      const usernameResponse = await profileService.checkUsernameAvailability(
        request.username
      );
      if (usernameResponse.statusCode !== "Success") {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Username is already in use",
          layer: "admin"
        });
        window.scrollTo(0, 0);
        this.loading = false;
        return;
      }
      // const usersByEmail = await userService.getUsersByEmail(request.email);
      // if (usersByEmail.some(x => x.userType !== "Customer")) {
      //   this.$store.commit("alert/setErrorAlert", {
      //     type: "alert-danger",
      //     message: "Email is already in use",
      //     layer: "admin"
      //   });
      //   this.loading = false;
      //   return;
      // }
      userService
        .addUser(request)
        .then(response => {
          if (response.statusCode == "Success") {
            this.$router.push("/admin/user-search").catch(() => {});
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-success",
              message: "User added successfully",
              layer: "admin"
            });
          } else {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
            window.scrollTo(0, 0);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
